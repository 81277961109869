import React, { useState, useEffect, useCallback } from 'react';
import { getUserProfile } from '../services';
import { Routes, useNavigate, Route } from 'react-router-dom';

const CommonContext = React.createContext({
    isLoading: true,
    currentOrganization: null,
    userInfo: {},
    users: [],
    permissions: [],
    features: [],
    featureRoleList: [],
    roles: [],
    roleList: [],
    referenceData: [],
    userPersonalization: {},
    restrictions: {},
    refetch: () => {},
    defaultData: {},
    switchCurrentOrganization: () => {},
    space: {
        org_count: 0,
        is_subscribe: false
    },
    currencyFormat: '',
    dateFormat: '',
    currentDefaultFormatDetails: {},
    currencyGroupStyle: {},
    currencySeparator: {},
    currencyDecimalSeparator: {}
});

const CommonProvider = ({ children }) => {
    const navigate = useNavigate();

    const [state, setState] = useState({
        isLoading: true,
        userInfo: {},
        users: [],
        permissions: [],
        features: [],
        featureRoleList: [],
        roles: [],
        roleList: [],
        referenceData: {},
        currencyFormat: '',
        dateFormat: '',
        refetch: async ({ setLoader = false }) => {}
    });

    const getDetails = useCallback(async () => {
        let token = localStorage.getItem('aT');

        if (!token) {
            navigate('/login');
            setState({
                ...state,
                isLoading: false
            });

            return null;
        }

        try {
            let details = await getUserProfile();

            if (details && details.data && details.data.doc) {
                console.log(details.data.doc);
                const { reference_data, user, user_role } = details.data.doc;

                setState({
                    ...state,
                    isLoading: false,
                    referenceData: reference_data ? reference_data : {},
                    userInfo: user,
                    permissions: user_role.features.reduce((prev, curr) => {
                        return [...prev, curr.feature_id];
                    }, [])
                });
            }
        } catch (err) {
            navigate('/login');

            setState({
                ...state,
                isLoading: false
            });
        }

        return null;
    }, []);

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <CommonContext.Provider value={state}>
            {state.isLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flex: '1',
                        height: '100vh'
                    }}
                >
                    <p style={{ color: '#cdced9', fontSize: '30px' }}>Loading...</p>
                </div>
            ) : (
                children
            )}
        </CommonContext.Provider>
    );
};

export { CommonProvider, CommonContext };
