import { IconDashboard } from '@tabler/icons';

const icons = { IconDashboard };

const enrollment = {
    id: 'enrollment',
    title: 'Enrollment',
    type: 'group',
    children: [
        {
            id: 'hospital',
            title: 'Hospital',
            type: 'item',
            url: '/hospital',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'user',
            title: 'User',
            type: 'item',
            url: '/user',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default enrollment;
