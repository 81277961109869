import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import getToken from 'utils/getToken';

const HOST = process.env.REACT_APP_HOST;

const getHospitals = async () => {
    return await axios.get(`${HOST}hospital`, {
        headers: {
            ...getToken()
        }
    });
};

export const useHospital = () => {
    return useQuery({ queryKey: ['HOSPITALS'], queryFn: getHospitals });
};

const createHospitalFn = async (data) => {
    return await axios.post(`${HOST}hospital`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreateHospital = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createHospitalFn,
        onSuccess: (data) => {
            toast.success('Hospital Created Successfully');
            client.invalidateQueries(['HOSPITALS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const updateHospitalFn = async ({ _id, __v, ...data }) => {
    return await axios.put(`${HOST}hospital/${_id}`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useUpdateHospital = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: updateHospitalFn,
        onSuccess: (data) => {
            toast.success('Hospital Updated Successfully');
            client.invalidateQueries(['HOSPITALS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const deleteHospitalFn = async (hospitalId) => {
    return await axios.delete(`${HOST}hospital/${hospitalId}`, {
        headers: {
            ...getToken()
        }
    });
};
export const useDeleteHospital = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteHospitalFn,
        onSuccess: (data) => {
            toast.success('Hospital Deleted Successfully');
            client.invalidateQueries(['HOSPITALS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};
