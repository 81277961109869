// material-ui
import { Link, Typography, Stack } from '@mui/material';
import FooterLogo from '../../assets/images/hashind-logo-footer.png';

// ==============================|| FOOTER - AUTHENTICATION 2 & 3 ||============================== //

const AuthFooter = () => (
    <Stack direction="row" justifyContent="center" alignItems="center" style={{ gap: '10px' }}>
        <img src={FooterLogo} alt="hashind logo" />
        <Typography variant="subtitle2" component={Link} href="https://hashind.com" target="_blank" underline="hover">
            Powered by Hashind
        </Typography>
    </Stack>
);

export default AuthFooter;
