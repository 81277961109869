import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';

import 'assets/scss/style.scss';
import config from './config';

import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient} contextSharing={true}>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </QueryClientProvider>
    </Provider>
);

serviceWorker.unregister();
