import enrollment from './enrollment';
import manage from './manage';
import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';

const menuItems = {
    items: [dashboard, enrollment, manage]
};

export default menuItems;
