import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import getToken from 'utils/getToken';

const HOST = process.env.REACT_APP_HOST;

const getAppointments = async ({ queryKey }) => {
    return await axios.get(`${HOST}appointment`, {
        headers: {
            ...getToken()
        },
        params: {
            ...(queryKey[1] ? { today: true } : {})
        }
    });
};

export const useAppointment = ({ showToday }) => {
    return useQuery({ queryKey: ['APPOINTMENTS', showToday], queryFn: getAppointments });
};

const createAppointmentFn = async (data) => {
    return await axios.post(`${HOST}appointment`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreateAppointment = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createAppointmentFn,
        onSuccess: (data) => {
            toast.success('Appointment Created Successfully');
            client.invalidateQueries(['APPOINTMENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const updateAppointmentFn = async ({ _id, __v, ...data }) => {
    return await axios.put(`${HOST}appointment/${_id}`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useUpdateAppointment = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: updateAppointmentFn,
        onSuccess: (data) => {
            toast.success('Appointment Updated Successfully');
            client.invalidateQueries(['APPOINTMENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const deleteAppointmentFn = async (appointmentId) => {
    return await axios.delete(`${HOST}appointment/${appointmentId}`, {
        headers: {
            ...getToken()
        }
    });
};
export const useDeleteAppointment = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteAppointmentFn,
        onSuccess: (data) => {
            toast.success('Appointment Deleted Successfully');
            client.invalidateQueries(['APPOINTMENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};
