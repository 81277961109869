import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import getToken from 'utils/getToken';

const HOST = process.env.REACT_APP_HOST;

const getInventory = async () => {
    return await axios.get(`${HOST}inventory`, {
        headers: {
            ...getToken()
        }
    });
};

export const useInventory = () => {
    return useQuery({ queryKey: ['INVENTORY'], queryFn: getInventory });
};

const createInventoryFn = async (data) => {
    return await axios.post(`${HOST}inventory`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreateInventory = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createInventoryFn,
        onSuccess: (data) => {
            toast.success('Inventory Created Successfully');
            client.invalidateQueries(['INVENTORY']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const updateInventoryFn = async ({ _id, __v, value, ...data }) => {
    return await axios.put(`${HOST}inventory/${_id}`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useUpdateInventory = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: updateInventoryFn,
        onSuccess: (data) => {
            toast.success('Inventory Updated Successfully');
            client.invalidateQueries(['INVENTORY']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const deleteInventoryFn = async (inventoryId) => {
    return await axios.delete(`${HOST}inventory/${inventoryId}`, {
        headers: {
            ...getToken()
        }
    });
};
export const useDeleteInventory = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteInventoryFn,
        onSuccess: (data) => {
            toast.success('Inventory Deleted Successfully');
            client.invalidateQueries(['INVENTORY']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const createInventoryOrderFn = async (data) => {
    return await axios.post(`${HOST}inventory/order`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreateInventoryOrder = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createInventoryOrderFn,
        onSuccess: (data) => {
            toast.success('Inventory Order Created Successfully');
            client.invalidateQueries(['INVENTORY']);
            window.open(`${HOST}inventory/${data.data.doc}`, '__blank');
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};
