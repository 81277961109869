import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

const Hospital = Loadable(lazy(() => import('views/hospital')));
const Inventory = Loadable(lazy(() => import('views/inventory')));
const Appointment = Loadable(lazy(() => import('views/appointment')));
const Invoice = Loadable(lazy(() => import('views/invoice')));
const InvoiceList = Loadable(lazy(() => import('views/invoice/invoiceList')));
const User = Loadable(lazy(() => import('views/user')));
const Patient = Loadable(lazy(() => import('views/patient')));
const PatientOverview = Loadable(lazy(() => import('views/patient/patientOverview')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

const ProtectedRoute = ({ children }) => {
    // if (keycloak.authenticated) {
    return children;
    // }

    return null;
};

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <ProtectedRoute>
            <MainLayout />
        </ProtectedRoute>
    ),
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: 'hospital',
            element: <Hospital />
        },
        {
            path: 'user',
            element: <User />
        },
        {
            path: 'inventory',
            element: <Inventory />
        },
        {
            path: 'appointment',
            element: <Appointment />
        },
        {
            path: 'patient',
            element: <Patient />
        },
        {
            path: 'patient/:patientId',
            element: <PatientOverview />
        },
        {
            path: 'invoice',
            element: <Invoice />
        },
        {
            path: 'invoice/list',
            element: <InvoiceList />
        }
    ]
};

export default MainRoutes;
