import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';

const HOST = process.env.REACT_APP_HOST;

const userLogin = async (data) => {
    return await axios.post(`${HOST}login`, data);
};
export const useUserLogin = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: userLogin,
        onSuccess: (data) => {
            toast.success('Login Successful');
            if (props.onSuccess) {
                props.onSuccess(data);
            }
        },
        onError: (e) => {
            console.log('LOGIN ERR', e);
            toast.error('Login Failed');
            props?.onError?.(e);
        }
    });
};
