import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import getToken from 'utils/getToken';

const HOST = process.env.REACT_APP_HOST;

const getPatients = async () => {
    return await axios.get(`${HOST}patient`, {
        headers: {
            ...getToken()
        }
    });
};

export const usePatient = () => {
    return useQuery({ queryKey: ['PATIENTS'], queryFn: getPatients });
};

const createPatientFn = async (data) => {
    return await axios.post(`${HOST}patient`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreatePatient = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createPatientFn,
        onSuccess: (data) => {
            toast.success('Patient Created Successfully');
            client.invalidateQueries(['PATIENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const updatePatientFn = async ({ _id, __v, ...data }) => {
    return await axios.put(`${HOST}patient/${_id}`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useUpdatePatient = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: updatePatientFn,
        onSuccess: (data) => {
            toast.success('Patient Updated Successfully');
            client.invalidateQueries(['PATIENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const deletePatientFn = async (patientId) => {
    return await axios.delete(`${HOST}patient/${patientId}`, {
        headers: {
            ...getToken()
        }
    });
};
export const useDeletePatient = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: deletePatientFn,
        onSuccess: (data) => {
            toast.success('Patient Deleted Successfully');
            client.invalidateQueries(['PATIENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const getPatientById = async (props) => {
    console.log('>> PROPS', props);
    let { patientId } = props.queryKey[1];
    return await axios.get(`${HOST}patient/${patientId}`, {
        headers: {
            ...getToken()
        }
    });
};

export const usePatientById = (props) => {
    return useQuery({
        queryKey: [
            `PATIENT-${props.patientId}`,
            {
                patientId: props.patientId
            }
        ],
        queryFn: getPatientById
    });
};

const addPatientRecordFn = async ({ formdata, patientId }) => {
    return await axios.post(`${HOST}patient/${patientId}/records`, formdata, {
        headers: {
            ...getToken()
            // 'content-type': 'multipart/form-data'
        }
    });
};
export const useAddPatientRecords = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: addPatientRecordFn,
        onSuccess: (data) => {
            toast.success('Patient Records Added Successfully');
            // client.invalidateQueries(['PATIENTS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

export const getPatientRecordFile = async (props) => {
    let { patientId, date, filename } = props;

    window.open(`${HOST}patient/${patientId}/records/${date}/${filename}`, '__blank');

    // return await axios
    //     .get(`${HOST}patient/${patientId}/records/${date}/${filename}`, {
    //         headers: {
    //             ...getToken()
    //         }
    //     })
    //     .then((res) => {
    //         console.log(res);

    //         const downloadUrl = window.URL.createObjectURL(new Blob(res.data));
    //         // const downloadUrl = window.URL.createObjectURL(res.data);

    //         window.open(downloadUrl, '__blank');
    //     });
};
