import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import getToken from 'utils/getToken';

const HOST = process.env.REACT_APP_HOST;

const getUsers = async () => {
    return await axios.get(`${HOST}user`, {
        headers: {
            ...getToken()
        }
    });
};

export const useUser = () => {
    return useQuery({ queryKey: ['USERS'], queryFn: getUsers });
};

const getUserDashboard = async () => {
    return await axios.get(`${HOST}user/dashboard`, {
        headers: {
            ...getToken()
        }
    });
};

export const useUserDashboard = () => {
    return useQuery({ queryKey: ['DASHBOARD'], queryFn: getUserDashboard });
};

const createUserFn = async (data) => {
    return await axios.post(`${HOST}user`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useCreateUser = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: createUserFn,
        onSuccess: (data) => {
            toast.success('User Created Successfully');
            client.invalidateQueries(['USERS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const updateUserFn = async ({ _id, __v, ...data }) => {
    return await axios.put(`${HOST}user/${_id}`, data, {
        headers: {
            ...getToken()
        }
    });
};
export const useUpdateUser = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: updateUserFn,
        onSuccess: (data) => {
            toast.success('User Updated Successfully');
            client.invalidateQueries(['USERS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

const deleteUserFn = async (userId) => {
    return await axios.delete(`${HOST}user/${userId}`, {
        headers: {
            ...getToken()
        }
    });
};
export const useDeleteUser = (props) => {
    const client = useQueryClient();

    return useMutation({
        mutationFn: deleteUserFn,
        onSuccess: (data) => {
            toast.success('User Deleted Successfully');
            client.invalidateQueries(['USERS']);
            if (props.onSuccess) {
                props.onSuccess();
            }
        },
        onError: (e) => {
            props?.onError?.(e);
        }
    });
};

export const getUserProfile = async () => {
    return await axios.get(`${HOST}user/profile`, {
        headers: {
            ...getToken()
        }
    });
};
