import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { CommonProvider } from 'provider/CommonProvider';

import Routes from 'routes';

import themes from 'themes';

import NavigationScroll from 'layout/NavigationScroll';
import InvoicePage from 'ui-component/InvoicePage';

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CommonProvider>
                    <CssBaseline />
                    <NavigationScroll>
                        {/* <InvoicePage data={{}} onChange={() => {}} /> */}
                        <Routes />
                    </NavigationScroll>
                </CommonProvider>
            </ThemeProvider>
            <ToastContainer />
        </StyledEngineProvider>
    );
};

export default App;
