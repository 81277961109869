import { IconDashboard } from '@tabler/icons';

const icons = { IconDashboard };

const enrollment = {
    id: 'manage',
    title: 'Manage',
    type: 'group',
    children: [
        {
            id: 'patient',
            title: 'Patient',
            type: 'item',
            url: '/patient',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'inventory',
            title: 'Inventory',
            type: 'item',
            url: '/inventory',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'appointment',
            title: 'Appointment',
            type: 'item',
            url: '/appointment',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'invoice',
            title: 'Invoice',
            type: 'item',
            url: '/invoice',
            icon: icons.IconDashboard,
            breadcrumbs: false
        }
    ]
};

export default enrollment;
